import { makeStyles } from '@material-ui/core/styles';
import {
  colorBgSecondary,
  colorPrimary,
  color3,
} from '../utils/elements/muiStyles';

export const imageLength = 314;

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: 0,
    maxWidth: 1280,
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  iconButton: {
    padding: 0,
  },
  container: {
    paddingTop: 15,
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: 30,
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 1280,
    '& div': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  scrollBar: {
    overflowX: 'auto',
  },
  noScrollBar: {
    overflowX: 'hidden',
  },
  marcasContainer: {
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      marginTop: 2,
    },
    backgroundColor: color3,
  },
  marcaCirculo: {
    minWidth: 120,
  },
  seleccionContainer: {
    marginTop: 4,
  },
  marginAuto: {
    margin: '0 auto',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  bgSecondary: {
    backgroundColor: colorBgSecondary,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    marginBottom: 0,
  },
  borderRight: {
    borderRight: `1px solid ${colorBgSecondary}`,
  },
  padding: {
    padding: '24px 0px',
  },
  logoImg: {
    paddingTop: 8,
    '& img': {
      marginBottom: 0,
    },
  },
  headerContainer: {
    paddingLeft: 16,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
    },
  },
  shopButton: {
    width: '100%',
  },
  menuList: {
    zIndex: 2,
  },
  menuButton: {
    cursor: 'pointer',
    padding: 12,
    '&:hover': {
      backgroundColor: colorPrimary,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: colorPrimary,
      color: '#fff',
    },
  },
  menuItem: {
    padding: 12,
  },
}));
