import { makeStyles } from '@material-ui/core/styles';

const imageLength = 314;

export default makeStyles((theme) => ({
  bannerText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px 10px',
    color: '#000',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      transform: 'translate(-50%, -50%)',
    },
    '& h5': {
      marginTop: 5,
      marginBottom: 5,
      [theme.breakpoints.down('md')]: {
        // fontSize: '1em',
      },
      [theme.breakpoints.down('sm')]: {
        // fontSize: '1.2em',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1em',
      },
    },
  },
  banner: {
    position: 'relative',
    height: imageLength, // 30vh
    // maxHeight: imageLength,
    [theme.breakpoints.down('md')]: {
      height: imageLength / 1.5,
    },
    /*
    [theme.breakpoints.down('sm')]: {
      height: imageLength / 2,
    },
    [theme.breakpoints.down('xs')]: {
      height: imageLength / 3,
    }, */
    width: '100%',
    margin: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    marginBottom: 0,
  },
  carousel: {
    '& button': {
      zIndex: '1 !important',
    },
  },
  bannerContentRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 300,
    },
  },
}));
