import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Layout from '../Layout';
import BannersSection from './BannersSection';
import SeleccionSection from './SeleccionSection';

const MarcasSection = dynamic(() => import('./MarcasSection'));
const SaleSection = dynamic(() => import('./SaleSection'));

const HomePage = ({ bloques, slides, footerContent }) => (
  <Layout title="Home" footerCompact={false} footerContent={footerContent}>
    <main className="relative top-0 max-w-screen-xl p-0 ml-auto mr-auto">
      <BannersSection slides={slides} />
      <SeleccionSection bloques={bloques} />
      <MarcasSection />
      <SaleSection />
    </main>
  </Layout>
);

HomePage.propTypes = {
  bloques: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  slides: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  footerContent: PropTypes.string.isRequired,
};

export default HomePage;
