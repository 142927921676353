import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Carousel } from 'react-responsive-carousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Img from 'next/image';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

// import { colorAmarillo, colorAmarilloLigthen } from '../../utils/elements/muiStyles';
import { useNavigate } from '../../../lib/utils/navigation';
import Multiline from '../Multiline';
import Title from './Title';
import Subtitle from './Subtitle';
import Button from './Button';
import useStyles from './useStyles';

const BannersSection = ({ slides }) => {
  // eslint-disable-next-line react/destructuring-assignment
  const banners = slides.map((b) => ({
    ...b,
    titleStr: b.title.text,
    title: <Multiline>{b.title.text}</Multiline>,
    TitleComponent: Title({ color: b.title.color }),
    ...(b.subtitle
      ? {
        subtitle: <Multiline>{b.subtitle.text}</Multiline>,
        SubtitleComponent: Subtitle({ color: b.subtitle.color }),
      }
      : {}
    ),
  }));

  const styles = useStyles();
  const isSmDown = useMediaQuery('(max-width:959.95px)');
  const isMd = useMediaQuery('@media (min-width:960px) and (max-width:1279.95px)');
  const navigate = useNavigate();

  return (
    <div className={styles.carousel} data-testid="carousel-home">
      <Carousel
        {...{
          showThumbs: false,
          autoPlay: true,
          infiniteLoop: true,
          showStatus: false,
        }}
      >
        {banners.map((b, idx) => {
          const image = (isSmDown && b.image.mobile) || b.image.desktop;
          return (
            <div key={b.titleStr} className={styles.banner}>
              <Img src={image} alt={b.alt} layout="fill" className={styles.image} priority={idx === 0} />
              <Grid
                container
                direction={isMd ? 'row' : 'column'}
                justifyContent="center"
                alignItems="center"
                className={clsx(styles.bannerText, styles[b.className])}
              >
                <Grid item xs={12} lg={8} className={clsx(styles.textCenter)}>
                  <b.TitleComponent>{b.title}</b.TitleComponent>
                  {b.subtitle && (
                    <b.SubtitleComponent>{b.subtitle}</b.SubtitleComponent>
                  )}
                </Grid>
                <Button
                  onClick={() => {
                    navigate(b.callToAction.url);
                  }}
                >
                  {b.callToAction.text}
                </Button>
                {b.link?.text && (
                  <Link href={b.link.url} passHref>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="pt-0 md:pt-1 underline">
                      {b.link.text}
                    </a>
                  </Link>
                )}
              </Grid>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

BannersSection.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default BannersSection;
