import { makeStyles } from '@material-ui/core/styles';
import {
  colorBgSecondary,
  color4,
  colorPrimaryOfertas,
  // colorPrimaryLighten, colorPrimary, colorAmarillo,
} from '../../utils/elements/muiStyles';
import { imageLength } from '../useStyles';

export default makeStyles((theme) => ({
  seleccionButton1: {
    fontFamily: 'Hammersmith One, Tahoma, Arial, Verdana, sans-serif',
    fontDisplay: 'swap',
    backgroundColor: colorPrimaryOfertas,
    border: 'none',
    fontSize: '1.2rem',
    padding: '2px 5px',
    marginBottom: 10,
    marginTop: '-5px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
      fontSize: '1rem',
    },
    color: '#000',
    margin: 'auto',
    width: '50%',
    fontWeight: 700,
    letterSpacing: 1.5,
    '&:hover': {
      // color: colorAmarillo,
      // border: `2px solid ${colorAmarillo}`,
      color: '#fff',
      backgroundColor: '#000',
    },
  },
  seleccionCardText: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  seleccionTitle: {
    fontFamily:
      'Bree Regular, Avenir Next, Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif',
    fontDisplay: 'swap',
    textAlign: 'center',
    fontSize: 30,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  seleccionSubtitle: {
    color: '#000',
    textAlign: 'center',
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  seleccionCard: {
    backgroundColor: color4,
    height: imageLength,
    [theme.breakpoints.down('md')]: {
      height: imageLength / 1.5,
    },
    [theme.breakpoints.down('sm')]: {
      height: imageLength / 2,
    },
    [theme.breakpoints.down('xs')]: {
      // height: imageLength / 3,
    },
    border: `1px solid ${colorBgSecondary}`,
    [theme.breakpoints.up('md')]: {
      maxWidth: 640,
      marginTop: 4,
      marginBottom: 4,
    },
  },
  seleccionCardInner: {
    // display: 'flex',
    position: 'relative',
  },
  seleccionCardContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: `calc(100% - ${imageLength}px)`,
    height: imageLength,
    [theme.breakpoints.down('md')]: {
      height: imageLength / 1.5,
      width: `calc(100% - ${imageLength / 1.5}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      height: imageLength / 2,
      width: `calc(100% - ${imageLength / 2}px)`,
      '& > .MuiCardContent-root': {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${imageLength / 2}px)`,
    },
  },
  cardMedia: {
    display: 'block !important',
    position: 'absolute !important',
    top: 0,
    left: 0,
    height: imageLength,
    width: imageLength,
    [theme.breakpoints.down('md')]: {
      height: imageLength / 1.5,
      width: imageLength / 1.5,
    },
    [theme.breakpoints.down('sm')]: {
      height: imageLength / 2,
      width: imageLength / 2,
    },
    [theme.breakpoints.down('xs')]: {
      height: imageLength / 2,
      width: imageLength / 2,
    },
  },
}));
