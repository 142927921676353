import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const light = '#fff';
const dark = '#000';
const borderWidth = 3;

export default withStyles((theme) => ({
  root: {
    fontFamily: 'Hammersmith One, Arial, Verdana, sans-serif',
    fontDisplay: 'swap',
    // fontFamily: 'FuturaPT, Tahoma, Arial, Verdana, sans-serif',
    // fontWeight: 700,

    // position: 'absolute',
    // left: '50%',
    // transform: 'translateX(-50%)',
    // letterSpacing: 4,
    fontSize: 20,
    padding: '9px 45px',
    [theme.breakpoints.down('md')]: {
      margin: '20px auto',
      // letterSpacing: 3,
      fontSize: 14,
      padding: '7px 35px',
      // bottom: -45,
    },

    backgroundColor: light,
    border: `${borderWidth}px solid ${dark}`,
    color: dark,
    borderRadius: 0,
    '&:hover': {
      border: `${borderWidth}px solid ${light}`,
      backgroundColor: dark,
      color: light,
    },
  },
  label: {
    lineHeight: '21px',
  },
  // outlinedPrimary: {
  //   border: `2px solid ${dark}`,
  //   backgroundColor: 'transparent',
  //   color: colorPrimary,
  //   '&:hover': {
  //     border: `2px solid ${light}`,
  //     backgroundColor: dark,
  //     color: light,
  //   },
  // },
  // outlinedSecondary: {
  //   border: `2px solid ${colorBgPrimary}`,
  //   borderWidth: '2px !important',
  //   backgroundColor: 'transparent',
  //   color: colorBgPrimary,
  //   '&:hover': {
  //     border: `2px solid ${colorBgPrimary}`,
  //     backgroundColor: colorBgPrimary,
  //     color: colorPrimary,
  //   },
  // },
  // disabledButton: {
  //   border: `2px solid ${colorBgPrimary}`,
  //   backgroundColor: theme.palette.action.disabledBackground,
  //   color: theme.palette.action.disabled,
  // },
}))(Button);
