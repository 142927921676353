import React from 'react';
import PropTypes from 'prop-types';
import Img from 'next/image';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useNavigate } from '../../../lib/utils/navigation';

import Multiline from '../Multiline';
import useStyles from './useStyles';

const SeleccionSection = ({ bloques }) => {
  const styles = useStyles();
  const isMd = useMediaQuery('(min-width:960px)');
  const isLg = useMediaQuery('(min-width:1280px)');
  const navigate = useNavigate();

  const selecciones = bloques.map((b) => ({
    ...b,
    title: <Multiline>{b.title}</Multiline>,
    subtitle: <Multiline>{b.subtitle}</Multiline>,
    titleStr: b.title.replace('💚', 'o'),
    subtitleStr: b.subtitle.replace('💚', 'o'),
  }));

  return (
    <div className="w-full flex flex-wrap box-border justify-center">
      {selecciones.map((s, index) => {
        const imageDim = (isLg && 315) || (isMd && 210) || 157;
        const { image } = s;
        const imgAlt = `${s.titleStr} - ${s.subtitleStr}`;
        return (
          <div
            key={s.titleStr + s.subtitleStr}
            className={`m-0 box-border w-full sm:w-6/12 ${index % 2 === 0 ? 'md:pr-1' : 'md:pl-1'}`}
          >
            <div className={`shadow-md bg-white text-[rgba(0, 0, 0, 0.87)] rounded overflow-hidden ${styles.seleccionCard}`}>
              <div className="w-full flex flex-wrap box-border relative">
                {(index % 2 === 0 || true) && (
                  <Img
                    className={styles.cardMedia}
                    src={image}
                    alt={imgAlt}
                    onClick={() => navigate(s.callToAction.url)}
                    width={imageDim}
                    height={imageDim}
                    layout="fixed"
                  />
                )}
                <div className={styles.seleccionCardContent}>
                  <div className={`p-4 ${styles.seleccionCardText}`}>
                    <p className={`text-3xl text-center mb-1 ${styles.seleccionTitle}`}>
                      {s.title}
                    </p>
                    <p className={`font-normal leading-normal mb-0 ${styles.seleccionSubtitle}`}>
                      {s.subtitle}
                    </p>
                  </div>
                  <div className="flex p-2 items-center">
                    <button
                      type="button"
                      onClick={() => {
                        if (s.targetBlank) {
                          window.open(s.callToAction.url, 'pwin');
                        } else {
                          navigate(s.callToAction.url);
                        }
                      }}
                      className={`
                        rounded box-border cursor-pointer inline-flex outline-none
                        relative items-center select-none align-middle justify-center no-underline
                        min-w-[64px] leading-[1.75em] uppercase py-6
                        ${styles.seleccionButton1}
                      `}
                    >
                      {s.callToAction.text}
                    </button>
                  </div>
                </div>
                {index % 2 !== 0 && false && (
                  <Img
                    className={styles.cardMedia}
                    src={image}
                    alt={s.title}
                    title={s.title}
                    onClick={() => navigate(s.callToAction.url)}
                    width={imageDim}
                    height={imageDim}
                    layout="fixed"
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

SeleccionSection.propTypes = {
  bloques: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default SeleccionSection;
