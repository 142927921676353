import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Multiline = ({ sm, children }) => {
  const smDown = useMediaQuery('(max-width:959.95px)');
  if (!children) {
    return null;
  }
  if (!sm && smDown) {
    return children;
  }
  const texts = children.split('\n');
  return texts.map((t, idx) => (
    <React.Fragment key={t}>
      {t}
      {idx < texts.length - 1 && <br />}
    </React.Fragment>
  ));
};

export default Multiline;
