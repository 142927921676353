import { ExperimentalGetTinaClient } from '../../.tina/__generated__/types.ts';
import HomePage from '../components/HomePage';
import { addFooterContent } from '../features/legalPages/utils';

export default HomePage;

export const getStaticProps = addFooterContent(async () => {
  const client = ExperimentalGetTinaClient();
  let data = {};
  try {
    const [{ data: { getBloquesList } }, { data: { getBannerList } }] = await Promise.all([
      client.getBloquesList(),
      client.getBannerList(),
    ]);
    data = {
      getBloquesList,
      getBannerList,
    };
  } catch (e) {
    // swallow errors related to document creation
  }

  const getData = (b) => b.node.data;
  const isActivo = (b) => b.activo;
  const byPosition = (a, b) => a.posicion - b.posicion;

  const bloques = data.getBloquesList.edges
    .map(getData)
    .filter(isActivo)
    .sort(byPosition);

  const slides = data.getBannerList.edges
    .map(getData)
    .filter(isActivo)
    .sort(byPosition);

  return {
    props: {
      bloques,
      slides,
    },
  };
});
