import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const TypographyWithStyles = (variant = { color: '#000' }) => withStyles((theme) => ({
  root: {
    // fontWeight: 'bolder',

    fontSize: 28,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },

    ...variant,
  },
}))(Typography);

export default TypographyWithStyles;
