import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const TypographyWithStyles = (variant = { color: '#000' }) => withStyles((theme) => ({
  root: {
    fontFamily:
        'Bree Regular, Avenir Next, Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif',
    fontWeight: 'bolder',
    fontDisplay: 'swap',
    fontSize: 40,
    lineHeight: 1.6,
    letterSpacing: 3,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: 1.2,
    },

    ...variant,
  },
}))(Typography);

export default TypographyWithStyles;
